import { Component, OnInit } from '@angular/core';
import { HeaderComponent } from "../header/header.component";
import { FooterComponent } from "../footer/footer.component";
import { Router, RouterLink } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { AuthState } from '../state/auth/auth.state';
import { getUserLogin } from '../state/auth/auth.selectors';
import { AuthActions } from '../state/auth';
import { Store } from '@ngrx/store';


@Component({
  selector: 'app-faq',
  standalone: true,
  templateUrl: './faq.component.html',
  styleUrl: './faq.component.scss',
  imports: [HeaderComponent, FooterComponent, RouterLink],
})
export class FaqComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private store: Store<AuthState>,
    private router: Router
  ) {}

  isUserLoggedIn = false;
  email: string = 'support@sprucepower.com';

  navigateToBilling() {
    window.open('https://3282543.ecommerce.zuora.com/auth/auth0', '_blank');
  }

  login(url: string) {
    this.store.dispatch(AuthActions.login());
    this.authService.isAuthenticated$.subscribe((isAuthenticated) => {
      if (isAuthenticated) {
        if (url == 'https://3282543.ecommerce.zuora.com/auth/auth0') {
          window.location.href =
            'https://3282543.ecommerce.zuora.com/auth/auth0';
        } else {
          this.router.navigate([url]);
        }
      }
    });
  }

  ngOnInit(): void {
    this.authService.isAuthenticated$.subscribe((isAuthenticated) => {
      if (isAuthenticated) {
        this.store.dispatch(AuthActions.loginSuccess());
      }
    });

    this.store
      .select(getUserLogin)
      .subscribe((userLogin) => (this.isUserLoggedIn = userLogin));
  }
}
